<template>
  <div id="help">
    <TopNavigation arrowAction="/" helpAction="/help" :isHelpVisible="false" />

    <div style="height: 10px"></div>

    <div class="flex justify-center items-center lg:block flex-col">
      <h2 class="text-3xl font-fredoka block test-center mx-auto">FAQ</h2>
      <p
        class="block test-center mx-auto text-sm mt-2"
        style="font-family: 'Open-Sans', sans-serif"
      >
        Frequently Asked Questions
      </p>
    </div>

    <VueFaqAccordion :items="myItems" />

    <div
      class="flex items-center justify-center flex-col mt-12"
      style="font-family: 'Open-Sans', sans-serif"
    >
      <div class="font-bold text-center text-sm mb-2">
        Still have a question?
      </div>
      <a
        href="#"
        class="text-center text-[#9E9E9E] mt-0 leading-tight text-sm mx-auto"
        >Contact our support team with your question and we’ll be more than
        happy to answer!</a
      >
    </div>
  </div>
</template>

<script>
import VueFaqAccordion from "vue-faq-accordion";
import TopNavigation from "@/pages/login/views/TopNavigation.vue";

export default {
  components: {
    VueFaqAccordion,
    TopNavigation,
  },
  data() {
    return {
      myItems: [
        {
          title: "How do I get to use the Sonik Pocket app?",
          value:
            "You can download the Sonik Pocket app from the Google Play and Apple Store for Android and iOS devices.",
        },
        {
          title: "How does Sonik Pocket work exactly?",
          value:
            "Sonik Pocket is a free-to-use, educational pocket money application for children and parents/guardians. Guardians set missions for their children – mini missions (washing the dishes) and skill missions (learning their times tables). Children unlock pocket money within the platform for completing each mission which they can choose to save (into their Sonik Pocket wallet) or spend (in our integrated e-Commerce store).",
        },
        {
          title: "How much does this service cost?",
          value:
            "Sonik Pocket is completely free for users, with no hidden subscription charges or transaction fees. What you put into the platform is what your child will receive i.e. if you load £10 into your account, your child will receive £10.",
        },
        {
          title: "How do I know that the items my child purchases are safe?",
          value:
            "All items listed on the eCommerce store are age-appropriate and suitable for 5-12 year olds.",
        },
        {
          title: "Why is Sonik spelled with a ‘k’?",
          value:
            "This is a question that we get asked often! The reason that it is spelled with a k is that when we were thinking of names, we asked 10 children to spell Sonik for us - all 10 of the children spelled it with a k! So we thought ‘Hey! This is a platform for kids’ so we will spell it how kids want it.",
        },
        {
          title: "Does my child need a bank card to use the app?",
          value:
            "We are an early learning pocket money app where everything is done exclusively on the app, there is no need for a bank card.",
        },
        {
          title: "How do I know my money is safe on Sonik Pocket?",
          value:
            "We are partnered with EMI agent Modulr Finance, allowing funds to safely be transferred to and from the platform - rest assured that your money is secure.",
        },
        {
          title:
            "Help! I don’t know how to speak to my children about financial education!",
          value:
            "Don’t worry, you’re not alone! 72% of guardians have said that they struggle to discuss financial education with their children. We have a wide range of tools and resources available to combat this common issue!",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.main-top-nav {
  background: white;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 100000;
  padding: 15px;
}

#help {
  padding: 80px 20px 20px 20px;
}

.faq {
  padding: 0 !important;
}

.accordion {
  border: 0 !important;
  border-bottom: 1px solid #e5e5e5 !important;
  font-family: "Open-Sans", sans-serif;
  border-radius: 0 !important;

  &__item {
    border: 0 !important;
    border-bottom: 1px solid #e5e5e5 !important;
  }

  &__title {
    font-size: 13px;
    font-weight: bold;

    &.accordion__title_active {
      .accordion__title-text {
        color: #00bcd4;
      }
    }
  }

  &__title-text {
    color: #806157;
  }

  &__toggle-button {
    width: 12px;
    height: 12px;
    padding: 12px;

    &:before,
    &:after {
      background: #806157 !important;
    }
  }

  .accordion__toggle-button_active {
    &:before,
    &:after {
      background: #03b5d2 !important;
    }
  }

  &__value {
    font-size: 12px;
    color: #806157 !important;
  }
}
</style>
